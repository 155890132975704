import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators, PreloadedState, Store} from 'redux'
import {ControllerParams, CreateControllerFn} from '@wix/yoshi-flow-editor'
import {EVENTS_APP_ID, isRtlLanguage} from '@wix/wix-events-commons-statics'
import {createEventHandler} from '@wix/tpa-settings'
import {createUouBiMiddlewareWithBiParams, createUsersBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {DesignSection, TextSection, UserRole} from '../../commons/enums'
import {decodeInstance} from '../../commons/selectors/instance'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {getQueryParams} from '../../commons/services/navigation'
import {isMembersEventsPageInstalled} from '../../commons/utils/members-api'
import {createReduxStore} from '../../commons/utils/store'
import {getLanguage, getPageInfo, isEditor, isMobile} from '../../commons/utils/wix-code-api'
import {watchInstance} from '../../commons/actions/instance'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {TAB} from '../../commons/constants/navigation'
import {RIBBON} from '../../commons/constants/ribbons'
import {Actions, State, StoreExtraArgs} from './Widget/types/state'
import {userEventsLogger} from './Widget/user-events-logger'
import {Api} from './Widget/utils/api'
import {isPaidPlansInstalled} from './Widget/services/apps'
import {sortEvents} from './Widget/reducers/events'
import reducers from './Widget/reducers'
import {datesMiddleware} from './Widget/middlewares/date'
import * as eventsUsers from './Widget/bi/users-bi-events-map'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import {updateSettings, updateStyle} from './Widget/actions/sdk'
import {navigateToPage, onLinkNavigatedToPage} from './Widget/actions/navigate-to-page'
import {addLoginListener, loadMembersForEvents, openMembersModal} from './Widget/actions/members'
import {
  closeListLayoutItems,
  hideRibbonOnItems,
  openListLayoutItem,
  showRibbonOnItems,
  widgetLoaded,
} from './Widget/actions/layout'
import {loadEventsPage, reloadEvents} from './Widget/actions/events'
import {createEvent, shareEvent} from './Widget/actions/event'
import {
  updateComponent,
  updateComponentDraft,
  updateStyleParams,
  INJECT_COMPONENT_DRAFT,
} from './Widget/actions/component'
import {
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  subCalendarMonth,
  addCalendarMonth,
  resetCalendar,
} from './Widget/actions/calendar-layout'
import {WidgetSettingsEventsKeys} from './interfaces'
import {settingsSectionChanged, settingsTabChanged} from './Widget/actions/settings-panel'
import {getFirstEvent, getSelectedEvents} from './Widget/selectors/events'
import {textsSectionChanged} from './Widget/actions/fonts-panel'
import {AppProps} from './Widget/components/app/interfaces'

const createWidgetController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const componentEventHandler = createEventHandler(controllerParams.controllerConfig.config.publicData.COMPONENT || {})
  const experiments = controllerParams.flowAPI.experiments.all()
  const serverApi = new Api(controllerParams)
  let store = null

  return {
    pageReady: async () => {
      store = await createStore(controllerParams.controllerConfig, serverApi, experiments)
      pageReady({controllerParams, componentEventHandler, store, serverApi})
    },
    updateConfig: (_, newConfig) => {
      if (store) {
        store.dispatch(
          updateStyleParams({
            numbers: newConfig.style.styleParams.numbers,
            booleans: newConfig.style.styleParams.booleans,
          }),
        )
        componentEventHandler.notify(newConfig.publicData.COMPONENT || {})
      }
    },
  }
}

interface PageReadyParams {
  controllerParams: ControllerParams
  componentEventHandler: ReturnType<typeof createEventHandler>
  store: any
  serverApi: Api
}

const pageReady = async ({store, serverApi, controllerParams, componentEventHandler}: PageReadyParams) => {
  const {controllerConfig: controller} = controllerParams
  const language = getLanguage(controller.wixCodeApi)

  const [pageInfo, pageUrl] = await Promise.all([
    getPageInfo(controller.wixCodeApi),
    controller.wixCodeApi.site.getSectionUrl({
      sectionId: 'events',
      appDefinitionId: EVENTS_APP_ID,
    }),
  ])
  const state = store.getState()

  const props: Partial<AppProps> = {
    pageUrl,
    baseUrl: controller.wixCodeApi.location.baseUrl,
    queryParams: getQueryParams(controller.wixCodeApi),
    pageInfo,
    state,
    actions: exportedActions({
      store,
    }),
    isRTL: isRtlLanguage(language),
    // @ts-expect-error
    fitToContentHeight: true,
  }

  if (state.membersEnabled) {
    serverApi.onLogin(() => {
      store.dispatch(loadMembersForEvents() as any)
    })
  }

  controller.setProps(props)
  subscribeToStateChanges(controller, store)

  componentEventHandler.on(WidgetSettingsEventsKeys.TabChanged, (tab: TAB) =>
    store.dispatch(settingsTabChanged(tab) as any),
  )
  componentEventHandler.on(WidgetSettingsEventsKeys.SettingsSectionChanged, (designSection: DesignSection) =>
    store.dispatch(settingsSectionChanged(designSection) as any),
  )
  componentEventHandler.on(WidgetSettingsEventsKeys.ExpandEvent, () => {
    const event = getFirstEvent(store.getState())
    if (event) {
      store.dispatch(openListLayoutItem(event.id))
    }
  })
  componentEventHandler.on(WidgetSettingsEventsKeys.CollapseEvent, () => store.dispatch(closeListLayoutItems()))
  componentEventHandler.on(WidgetSettingsEventsKeys.ShowRibbon, (ribbon: RIBBON) =>
    store.dispatch(showRibbonOnItems(ribbon)),
  )
  componentEventHandler.on(WidgetSettingsEventsKeys.HideRibbon, () => store.dispatch(hideRibbonOnItems()))
  componentEventHandler.on(WidgetSettingsEventsKeys.TextsSectionChanged, (textSection: TextSection) =>
    store.dispatch(textsSectionChanged(textSection) as any),
  )
  componentEventHandler.on(WidgetSettingsEventsKeys.InjectComponentDraft, async payload => {
    if (payload) {
      await store.dispatch({
        type: INJECT_COMPONENT_DRAFT,
        payload,
      })
    }

    store.dispatch(reloadEvents() as any)
  })
  componentEventHandler.on(WidgetSettingsEventsKeys.HideEvent, (id: string) =>
    store.dispatch(
      updateComponentDraft({
        byEventId: {eventId: getSelectedEvents(store.getState()).filter(eventId => eventId !== id)},
      }) as any,
    ),
  )
}

const createStore = async (
  controller: ControllerParams['controllerConfig'],
  serverApi: Api,
  experiments: ExperimentsBag,
) => {
  const {wixCodeApi, platformAPIs, appParams, compId, config} = controller
  const initialData = await getInitialData(serverApi, controller, experiments)

  const biParams = {
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  }
  const biMiddleware = [
    createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
    createUouBiMiddlewareWithBiParams(biParams, eventsUou),
  ]

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData,
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
    },
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })

  store.dispatch(updateStyleParams(config.style.styleParams))
  store.dispatch(setBaseEnvironment() as any)
  store.dispatch(addLoginListener() as any)

  watchInstance(controller, store.dispatch)

  return store
}

const subscribeToStateChanges = (controller: ControllerParams['controllerConfig'], store: Store) => {
  const onStateChange = () => {
    const state: State = store.getState()
    controller.setProps({
      state,
    })
  }

  store.subscribe(onStateChange)
}

const getInitialData = async (
  serverApi: Api,
  {appParams, wixCodeApi, config: controllerConfig}: ControllerParams['controllerConfig'],
  experiments: ExperimentsBag,
): Promise<PreloadedState<State>> => {
  const [membersEnabled, paidPlansInstalled] = await Promise.all([
    isMembersEventsPageInstalled(wixCodeApi),
    isPaidPlansInstalled(wixCodeApi),
  ])

  const instance = appParams.instance
  const decodedInstance = decodeInstance(instance)
  const isTemplate = decodedInstance.siteIsTemplate
  const showcase = isTemplate || isEditor(wixCodeApi) || isMobile(wixCodeApi)
  const {widgetType, listLayout, recurringFilter} = controllerConfig.style.styleParams.numbers
  const {compId, language, locale, tz, viewMode} = serverApi

  const {
    component: {events = [], config, id},
    siteSettings,
    demoEvents,
    hasMoreEvents,
    dates,
  } = await serverApi.getWidgetData({
    members: membersEnabled,
    paidPlans: paidPlansInstalled,
    responsive: isResponsiveEditor(controllerConfig),
    showcase,
    widgetType,
    listLayout,
    recurringFilter,
    compId,
    language,
    locale,
    tz,
    viewMode,
  })

  return {
    experiments,
    events: {
      events: sortEvents(events),
      hasMore: hasMoreEvents,
      moreLoading: false,
    },
    siteSettings,
    demoEvents,
    multilingual: getMultilingualInitialState(wixCodeApi),
    component: {
      ...config,
      id,
    },
    membersEnabled,
    paidPlansEnabled: paidPlansInstalled,
    memberLoggedIn: wixCodeApi.user.currentUser.loggedIn,
    owner: wixCodeApi.user.currentUser.role === UserRole.ADMIN,
    instance: {
      instance,
      ...decodedInstance,
    },
    calendarLayout: {
      ...(dates.calendar ?? {}),
      loadedPages: dates.calendar?.referenceDate ? [dates.calendar.referenceDate] : [],
    },
    dates,
  } as PreloadedState<State>
}

const exportedActions = ({store}) => {
  const dispatchActions = {
    addCalendarMonth,
    subCalendarMonth,
    resetCalendar,
    createEvent,
    updateSiteSettings,
    updateComponent,
    updateComponentDraft,
    navigateToPage,
    onLinkNavigatedToPage,
    updateStyle,
    updateStyleParams,
    updateSettings,
    setBaseEnvironment,
    openListLayoutItem,
    closeListLayoutItems,
    openMonthlyCalendarPopup,
    closeMonthlyCalendarPopup,
    openMonthlyCalendarEvent,
    closeMonthlyCalendarEvent,
    widgetLoaded,
    openMembersModal,
    shareEvent,
    setFormFactor,
    loadEventsPage,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
  }
  return actions
}

export default createWidgetController
